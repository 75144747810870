@import url('https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;700&display=swap');

body {
    font-family: 'Gabarito', sans-serif;
}



// Here you can add other styles
.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    background: #003868;
}

a {
    color: #003868;
}

a:hover {
    color: #003868 !important;
}

.btn-primary:focus,
.btn-primary.focus {
    color: #fff;
    background-color: #003868 !important;
    border-color: #003868 !important;
}

.dropdown-item.active,
.dropdown-item:active {
    text-decoration: none;
    color: #003868;
    background-color: transparent !important;
}

.page-item.active .page-link {
    background-color: #003868;
    border-color: #003868;
}

.loader-img {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.8) url("/img/loader.gif") 50% 50% no-repeat;
    background-size: 80px;
}

.btn-primary {
    background-color: #003868;
}

.btn-primary:hover {
    background-color: #003868;
}

.btn-link {
    color: #003868;
}

.text-muted {
    color: red !important;
    font-size: 14px;
}

.c-sidebar {
    color: #fff;
    background: #003868 !important;
}

.cus_logo {
    width: 256px;
    border-radius: 0;
}

.bz-login-trans .btn-primary:focus,
.btn-primary.focus {
    background-color: #ffffff !important;
    box-shadow: none !important;
    color: #003868 !important;
    font-size: 16px;
}

/*.c-sidebar-unfoldable .cus_logo{
	width: 70px;
    margin-right: 14px;
}*/
.c-sidebar .c-sidebar-brand {
    background: #fff;
}

.res_logo {
    font-size: 22px;
    color: #003868;
    font-weight: 800;
}

.c-dark-theme .res_logo {
    color: #fff;
}

/*canvas.mapboxgl-canvas {
    width: 1388px !important;
    height: 562px !important;
}*/

/*.lala-bg {
    background-image: url(/img/bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}*/


.text-center {
    text-align: center !important;
}

.hovercard .cardheader {
    background: url(/img/1.png) #f2eff2;
    background-size: cover;
    height: 400px;
    background-repeat: no-repeat;
    position: relative;
    background-position: right;
}

.cardheader:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.4;
}

.hovercard .user-image {
    position: relative;
    height: 0;
}

.hovercard .user-image .avatar {
    margin-top: -100px;
}

.hovercard .user-image .avatar img {
    width: 125px;
    height: 125px;
    max-width: 155px;
    max-height: 155px;
    border-radius: 50%;
    border: 7px solid #fff;
}

.hovercard .user-image .icon-wrapper {
    position: absolute;
    bottom: -20px;
    left: 51%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    overflow: hidden;
    margin: 0 auto;
    font-size: 17px;
    -webkit-box-shadow: 0 0 6px 3px rgba(68, 102, 242, 0.1);
    box-shadow: 0 0 6px 3px rgba(68, 102, 242, 0.1);
}

.hovercard .info {
    padding: 30px;
}

.text-left {
    text-align: left !important;
}

.ttl-info h6 {
    font-size: 12px;
    color: #59667a;
}

.ttl-info span {
    font-size: 14px;
    color: #242934;
}

.hovercard .info .title {
    margin-bottom: 4px;
    font-size: 24px;
    line-height: 1;
    color: #2c323f;
    vertical-align: middle;
}

.hovercard .info .title a {
    color: #242934;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
}

.hovercard .info .desc {
    overflow: hidden;
    font-size: 14px;
    line-height: 20px;
    color: #59667a;
    text-overflow: ellipsis;
    text-transform: uppercase;
}

.mb_2 {
    margin-bottom: 25px !important;
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px #dedede;
    margin-top: 20px;
}

a:hover {
    text-decoration: none;
    color: #003868;
}

.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
    color: #fff;
}

.c-sidebar .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown-toggle {
    color: #fff;
    background: transparent;
}

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    color: #fff;
    background: rgba(255, 255, 255, 0.05);
}

.profile-name {
    padding-right: 10px;
    position: relative;
    top: 8px;
    color: #003868;
}

.btn-outline-primary {
    color: #003868;
    border-color: #003868;
}

.btn-outline-primary:hover {
    background-color: #003868;
    border-color: #003868;
}

.info-txt {
    color: #000 !important;
}

.c-sidebar-unfoldable .cus_logo {
    display: none;
}

.c-sidebar-unfoldable .c-sidebar-brand-full {
    display: block !important;
}

.c-sidebar-fixed .c-sidebar-brand-full {
    display: none;
}

/*FOR INVOICE*/

table.invoice {
    background: #fff;
    width: 100%;
}

table.invoice .num {
    font-weight: 200;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 14px;
}

table.invoice tr,
table.invoice td {
    background: #fff;
    text-align: left;
    font-weight: 400;
    color: #322d28;
}

table.invoice tr.header td h2 {
    text-align: right;
    font-size: 2rem;
    color: #003868;
}

table.invoice tr.intro td:nth-child(2) {
    text-align: right;
}

table.invoice tr.details>td {
    padding-top: 4rem;
    padding-bottom: 0;
}

table.invoice tr.totals td {
    padding-top: 0;
}

table.invoice tr.totals table tr td {
    padding-top: 0;
    padding-bottom: 10px;
}

table.invoice tr.totals table tr td:nth-child(1) {
    font-weight: 500;
}

table.invoice tr.totals table tr td:nth-child(2) {
    text-align: right;
    font-weight: 200;
}

table.invoice tr.totals table tr:nth-last-child(2) td {
    padding-bottom: 0.5em;
}

table.invoice tr.totals table tr:nth-last-child(2) td:last-child {
    position: relative;
}

table.invoice tr.totals table tr:nth-last-child(2) td:last-child:after {
    content: "";
    height: 4px;
    width: 100%;
    border-top: 1px solid #003868;
    border-bottom: 1px solid #003868;
    position: relative;
    right: 0;
    bottom: -0.575rem;
    display: block;
}

table.invoice tr.totals table tr.total td {
    font-size: 1.2em;
    padding-top: 0.5em;
    font-weight: 700;
}

table.invoice tr.totals table tr.total td:last-child {
    font-weight: 700;
}

.additional-info h5 {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #003868;
}

.additional-info p {
    margin: 0px 0px 5px;
}

.cat-tr td {
    border-top: 0px !important;
}

.cat-title {
    background-color: #f3f3f3 !important;
    font-size: 17px;
}

.cat-addon {
    padding-left: 40px !important;
    font-size: 12px;
    color: #292d78 !important;
    padding-bottom: 10px !important;
}

.cat-att {
    border-top: 0px !important;
    font-size: 16px;
}

.cat-item {
    padding-left: 40px !important;
}

/*END INVOICE*/

.card_bg {
    background-color: #f1f1f1 !important;
}

.media {
    display: flex;
    align-items: flex-start;
}

.mr-10,
.mx-10 {
    margin-right: 0.625rem !important;
}

.card {
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08) !important;
    background-color: #fff !important;
    border: none !important;
}

.text-primary2 {
    color: #f7992c !important;
}

/*FOR MENU*/

#_2iAdU ._R2FsI {
    margin: 10px !important;
}

._3r-ro {
    display: none !important;
}

#_2iAdU {
    box-shadow: none !important;
    background: #003868 !important;
    width: 100% !important;
    margin-top: 8px;
}

#_2iAdU:hover {
    box-shadow: none !important;
    background: #003868 !important;
}

._2Gsmq {
    box-shadow: none !important;
    display: none !important;
}

#_2iAdU:hover::before {
    opacity: 0 !important;
}

#_2iAdU ._2trtS>ul>li:hover {
    background: #003868 !important;
}

#_2iAdU ._3D598 {
    background: #003868 !important;
}

#_2iAdU ._1JYcn {
    background: #003868 !important;
}

#_2iAdU ._3D598 ul li:hover>a,
#_2iAdU ._3D598 ul li:hover>svg,
#_2iAdU ._3D598 ul li:hover>._1RBDr {
    color: #ffffff !important;
}

.form-control {
    font-size: 16px;
}

.form-control:focus {
    border-color: #e4e7ea;
    box-shadow: none !important;
}

.bz-login-wrap {
    width: 100%;
    max-width: 100%;
    padding: 0;
}

.bz-login-bg {
    background: #003868;
    width: 40%;
    height: 100vh;
}

.bz-login-box {
    flex: 0 0 100%;
    max-width: 100%;
}

.bz-login-trans {
    background-color: transparent !important;
    box-shadow: none !important;
    max-width: 540px;
    margin: 33% auto 0 auto;
}

.bz-login-trans h1 {
    color: #ffffff;
    margin-bottom: 0;
}

.bz-login-trans p {
    color: #ffffff;
}

.bz-login-trans .form-control {
    background-color: transparent !important;
    border: none;
    font-size: 16px;
}

.bz-login-trans ::-webkit-input-placeholder {
    color: #ffffff;
}

.bz-login-trans :-ms-input-placeholder {
    color: #ffffff;
}

.bz-login-trans ::placeholder {
    color: #ffffff;
}

.bz-login-trans .input-group-text {
    background-color: transparent;
    border-color: transparent;
}

.bz-login-trans .input-group {
    border: 1px solid #ffffff;
    margin-bottom: 0 !important;
    padding: 6px 0;
}

.bz-login-trans .input-group-text svg {
    color: #ffffff;
}

.bz-login-trans .btn-primary {
    background-color: #ffffff;
    box-shadow: none !important;
    color: #003868;
    font-size: 16px;
}

.bz-login-trans .btn-link {
    color: #ffffff;
    box-shadow: none;
    font-size: 16px;
}

.bz-login-trans .btn-link:hover {
    color: #ffffff;
}

.bz-login-trans .form-control {
    color: #ffffff;
}

.bz-login-trans .text-muted {
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 20px;
    background: #f43838;
    color: #ffffff !important;
    box-sizing: border-box;
    padding: 0 10px;
    line-height: 180%;
}

#_2iAdU * {
    font-size: 16px;
}

#_2iAdU ._3D598 ul li a {
    font-size: 13px;
}

.table th,
.table td {
    font-size: 15px;
}

.btn {
    font-size: 16px;
}

.btn-success {
    background-color: #003868;
    border-color: #003868;
}

.bz-date-time {
    color: #797980;
}

.pagination {
    margin-top: 18px;
}

.page-item {
    font-size: 16px;
}

.page-link {
    color: #003868;
}

.page-link:focus {
    box-shadow: none;
}

.bz-contact-box-bg {
    background: #ebedef;
    padding: 10px 30px;
    border-radius: 6px;
    margin: 0 10px;
}

.bz-contact-box-bg h6 {
    color: #003868;
    font-size: 14px;
}

.bz-address-card-bg {
    border-radius: 0.25rem;
    background-color: #fff;
}

.bz-address-card-bg .custome-card {
    background: #ebedef;
    border-radius: 6px;
}

.bz-address-card-bg .card-title {
    color: #003868;
    font-size: 16px;
}

.bz-address-card-bg .card-subtitle {
    color: #8a93a2;
    font-size: 15px;
}

#_2iAdU ._3D598 ul li:hover {
    background: transparent !important;
    color: #ffffff;
}

.custom-mdl .modal-dialog {
    max-width: none !important;
}

.modal-primary .modal-header {
    background-color: #003868;
}

.down_btn button {
    margin: 10px;
}

.in_btn {
    float: right;
}

.in_btn button {
    margin: 0 5px;
}

div.invoice-header {
    background-color: #eff2f4;
    color: black;
    border-bottom: 1px solid #4090de;
}

div.invoice-header>div>p {
    font-size: 1.2rem;
    font-weight: 350;
}

div.invoice-header>div>h1 {
    font-size: 4rem;
}

.invoice-table h5 {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #003868;
}

div.invoice-table>table.table>thead,
div.invoice-table>table.table>thead.thead>tr,
div.invoice-table>table.table>thead.thead>tr>th {
    border-top: none;
}

div.total-field {
    position: relative;
}

div.sub-table>table {
    padding-bottom: 0;
    margin-bottom: 0;
}

tr.last-row {
    margin-top: 25px;
    background-color: #f0f3f5;
    color: black;
    border-top: 1px solid #4090de;
}

p.footer {
    bottom: 0;
    width: 100%;
    background-color: #333;
    color: white;
    padding-top: 15px;
    border-top: 3px solid red;
}

tr.last-row>th,
tr.last-row>td {
    background-color: unset !important;
}

div.page-break {
    page-break-before: auto;
}

.table-borderless {
    box-shadow: 1px 0px 5px #c3c3c3;
    margin: 10px;
}

.table-borderless h4 {
    margin: 0;
}

.h5-title-clr {
    color: #4090de;
}

.details-shadow {
    box-shadow: 0px 0px 5px #bdbdbd;
    padding: 20px 0px;
}

@media only screen and (max-width: 1200px) {
    #_2iAdU ._2trtS>ul {
        width: 100% !important;
        display: inline-block !important;
    }

    #_2iAdU ._2trtS>ul>li {
        display: inline-block !important;
    }

    ._1Pj9u {
        top: 6px !important;
    }
}

@media (min-width: 576px) {
    .custom-mdl .modal-dialog {
        max-width: 500px;
        margin: 10px !important;
    }
}

.ReactModal__Content {
    inset: 180px 0 0 0 !important;
}

.msger-chat {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
}

.msger-chat::-webkit-scrollbar {
    width: 6px;
}

.msger-chat::-webkit-scrollbar-track {
    background: #ddd;
}

.msger-chat::-webkit-scrollbar-thumb {
    background: #bdbdbd;
}

.msg {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}

.msg:last-of-type {
    margin: 0;
}

.msg-img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    background: #ddd;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
}

.msg-bubble {
    max-width: 450px;
    padding: 15px;
    border-radius: 15px;
    background: var(--left-msg-bg);
}

.msg-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.msg-info-name {
    margin-right: 10px;
    font-weight: bold;
}

.msg-info-time {
    font-size: 0.85em;
}

.left-msg .msg-bubble {
    border-bottom-left-radius: 0;
}

.right-msg {
    flex-direction: row-reverse;
}

.right-msg .msg-bubble {
    background: var(--right-msg-bg);
    color: #fff;
    border-bottom-right-radius: 0;
}

.right-msg .msg-img {
    margin: 0 0 0 10px;
}

:root {
    --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    --msger-bg: #fff;
    --border: 2px solid #ddd;
    --left-msg-bg: #ececec;
    --right-msg-bg: #579ffb;
}

.item {
    position: relative;
    padding-top: 20px;
    display: inline-block;
    right: 30px;
}

.notify-badge {
    position: absolute;
    right: -20px;
    top: 10px;
    width: 20px;
    height: 20px;
    line-height: 22px;
    background: #4090de;
    text-align: center;
    border-radius: 50%;
    color: white;
}

.notify-badge1 {
    position: absolute;
    right: 35px;
    top: 10px;
    width: 20px;
    height: 20px;
    line-height: 22px;
    background: #4090de;
    text-align: center;
    border-radius: 50%;
    color: white;
}

.access-right tr td,
.access-right tr th {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #d8dbe0;
}

.access-right label {
    margin-top: 0px;
}

.pickup-dropoff-del {
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-color: #4090de;
    text-align: center;
    margin: 0;
    border-radius: 50px;
    color: #fff;
    line-height: 30px;
    font-size: 17px;
}

.modal-danger .modal-header {
    background-color: #003868;
}

.react-bs-table-no-data {
    padding: 0 !important;
    margin: 0 !important;
}

.pagination {
    float: right !important;
}

.tox .tox-editor-header {
    z-index: 0 !important;
}

/* ********* new add css start ********* */

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  display: flex;
  flex-wrap: wrap;
}

.justify-content-center {
  justify-content: center !important;
  align-items: center !important;
}

.login_vector {
  text-align: center;
  border-right: 1px solid #dbdbdb;
}

.loginlogo {
  text-align: center !important;
}

.text-muted {
  color: #536c79 !important;
  font-size: .938em !important;
}

.login-title {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.53125rem;
}

.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.cus_input {
  position: relative;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3e515b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #c2cfd6;
  border-radius: 0;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control {
  padding: 1rem 2.5rem 1rem 1.5rem !important;
  border-width: 1px !important;
  border-radius: 0.5rem !important;
  font-size: 16px !important;
  height: auto !important;
  line-height: normal !important;
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  overflow: visible;
  margin: 0;
  padding-right: 200px;
}

.form-control:focus {
  color: #000 !important; border-color: #013868!important; z-index: 0 !important; position: relative !important;
}

.input_icons {
  position: absolute;
  right: 20px;
  top: 12px;
  font-size: 20px;
  color: gray;
}
.forgot-link{ text-align: right;}
.signin-btn{
    width:100%;      
}

@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}

@media only screen and (max-width: 768px) {
  .mobileview {
    float: none;
    margin: 0 auto;
    width: 96% !important;
  }
}

/* ********* new add css end ********* */